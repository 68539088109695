import React from 'react';
import '../styles/text.less';
import { useIntl, FormattedHTMLMessage, Link } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';

const Custom404 = () => {
  const intl = useIntl();
  return (
    <Layout>
      <div className="index">
        <div className="intro">
        <FormattedHTMLMessage id="404.text" />
          {/* <h1 className="colorHighlight">
            {intl.formatMessage({ id: '404.headline' })}
          </h1>
          <h2>
            {intl.formatMessage({ id: '404.text' })}
          </h2>
          <p className="copy">
            {intl.formatMessage({ id: '404.copy' })}
          </p> */}

          <div className="genericbutton">
            <Link to="/">
              <button type="button">
              {intl.formatMessage({ id: '404.label' })}
                {/* <FormattedMessage id="404.label" /> */}
              </button>
            </Link>

          </div>


        </div>
      </div>
    </Layout>
  );
};

export default Custom404;
